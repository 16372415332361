import styled from 'styled-components';
import { Title as CommonTittle } from 'modules/Core/Common';

export const ModalBody = styled.div`
  padding: 32px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.config.colors.light};
  overflow-y: scroll;
  max-height: calc(90vh - 80px);

  &::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.secondary_100};
    border-radius: 20px;
    width: 5px;
  }
`;

export const IconContainer = styled.div`
  svg {
    path {
      fill: red;
    }
  }
`;

export const Title = styled(CommonTittle)`
  white-space: nowrap;
  color: ${(props) => props.theme.config.colors.tertiary};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  color: ${(props) => props.theme.config.colors.text_base};
  line-height: 24px;

  input {
    color: ${(props) => props.theme.config.colors.gray};
    font-size: 1.4rem;
  }

  input::placeholder {
    color: ${(props) => props.theme.config.colors.gray};
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  margin-top: 2.4rem;

  button {
    &#btn-cancel {
      color: ${(props) => props.theme.config.colors.gray};
    }
  }
`;
