import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useTheme } from 'styled-components';
import { Formik } from 'formik';

import { TextField } from '@mui/material';
import { Modal, Icon, Button, IconRoundBox } from 'modules/Core/Common';
import ConfirmDeletionSchema from './ConfirmDeletionSchema';

import * as S from './ConfirmDeletionModal.styles';

const I18N_PREFIX = 'commons.confirm-deletion-modal';

const WORD_TO_TYPE = 'DELETE';

export const ConfirmDeletionModal = ({
  loading,
  isOpen,
  onClose,
  onConfirm,
  resource,
}) => {
  const theme = useTheme();

  const doesMatch = (word) => word === WORD_TO_TYPE;

  return (
    <Modal isOpen={isOpen} setIsOpen={onClose} width="420px">
      <>
        <S.ModalBody>
          <S.Center>
            <S.IconContainer>
              <IconRoundBox xlarge withBg bg={theme.config.colors.tertiary_100}>
                <Icon size="32px" name="exclamation" />
              </IconRoundBox>
            </S.IconContainer>
            <S.Title size="quartenary">
              {i18n.t(`${I18N_PREFIX}.title`)}
            </S.Title>
          </S.Center>
          <Formik
            initialValues={{
              wordToType: '',
            }}
            validationSchema={ConfirmDeletionSchema}
            onSubmit={() => onConfirm()}
          >
            {({ values, dirty, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <S.Info>
                  <p>{i18n.t(`${I18N_PREFIX}.description`, { resource })}</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: i18n.t(`${I18N_PREFIX}.type-delete`),
                    }}
                  />
                  <TextField
                    size="medium"
                    id="wordToType"
                    name="wordToType"
                    inputProps={{
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    placeholder={i18n.t(`${I18N_PREFIX}.type-the-keyword`)}
                    helperText={
                      dirty &&
                      !doesMatch(values.wordToType) &&
                      i18n.t(`${I18N_PREFIX}.capslock-keyword`)
                    }
                    error={dirty && !doesMatch(values.wordToType)}
                    sx={{
                      '.MuiFormHelperText-root': {
                        fontSize: '12px',
                      },
                    }}
                  />
                </S.Info>
                <S.Footer>
                  <Button
                    type="button"
                    medium
                    light
                    id="btn-cancel"
                    disabled={loading}
                    onClick={onClose}
                  >
                    {i18n.t('commons.buttons.cancel-button')}
                  </Button>
                  <Button
                    type="submit"
                    medium
                    danger
                    disabled={!doesMatch(values.wordToType) || loading}
                    loading={loading}
                    hasLoading
                  >
                    {i18n.t('commons.buttons.confirm-button')}
                  </Button>
                </S.Footer>
              </form>
            )}
          </Formik>
        </S.ModalBody>
      </>
    </Modal>
  );
};

ConfirmDeletionModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  resource: PropTypes.string,
};
