import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useTheme } from 'styled-components';
import { ButtonBase } from '@mui/material';

export function TableToolbarClear({ onClick }) {
  const theme = useTheme();

  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        px: 1,
        color: theme.config.colors.gray,
        borderRadius: '4px',
        height: 28,
        transition: 'all 0.2s',
        '&:hover': {
          color: theme.config.colors.primary,
        },
      }}
    >
      {i18n.t('common-words.clear')}
    </ButtonBase>
  );
}

TableToolbarClear.propTypes = {
  onClick: PropTypes.func,
};
