import { useMemo } from 'react';

export function useSelectionStatus(allItems, selectedItems) {
  const areAllItemsSelected = useMemo(() => {
    if (
      !allItems ||
      !allItems?.length ||
      !selectedItems ||
      !selectedItems?.length
    )
      return false;
    return allItems.every((item) => selectedItems.includes(item));
  }, [allItems, selectedItems]);

  return { areAllItemsSelected };
}
