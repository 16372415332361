import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Stack } from '@mui/material';
import { Modal, Title, ProgressBar, Button } from 'modules/Core/Common';

export const ProgressBarModal = ({ isOpen, progress, onClose, onCancel }) => {
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={onClose}
      width="480px"
      offCloseOnOverlayClick
    >
      <Stack
        sx={{
          p: 4,
          gap: 1,
        }}
      >
        <Stack
          sx={{
            gap: 1,
          }}
        >
          <Title size="default" tag="h2" style={{ fontSize: '1.6rem' }}>
            {i18n.t('commons.progress-bar-modal.title')}
          </Title>
          <Title size="mico" fontWeight="normal" tag="p">
            {i18n.t('commons.progress-bar-modal.description')}
          </Title>
        </Stack>
        <ProgressBar value={progress} />
        <Button
          type="button"
          medium
          danger
          transparent
          onClick={onCancel}
          style={{ alignSelf: 'center' }}
        >
          {i18n.t('commons.progress-bar-modal.cancel')}
        </Button>
      </Stack>
    </Modal>
  );
};

ProgressBarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  progress: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
