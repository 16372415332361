import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Stack } from '@mui/material';
import { MUICheckbox } from 'modules/Core/Common';

export function TableCheckbox({ isSelected, onSelect }) {
  const theme = useTheme();

  return (
    <Stack
      component="td"
      sx={{
        '&&&': {
          width: 48,
          height: 60,
          px: 2,
        },
        '.MuiTypography-root': {
          fontSize: '14px',
          lineHeight: '16px',
          color: theme.config.colors.gray,
        },
      }}
    >
      <MUICheckbox
        checked={!!isSelected}
        onChange={(e) => onSelect(e.target.checked)}
        checkboxProps={{
          disableRipple: true,
        }}
      />
    </Stack>
  );
}

TableCheckbox.propTypes = {
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};
