import { useMemo } from 'react';
import { useFormattedDate } from 'modules/Core/Hooks';

/**
 * Hook personalizado para determinar se o email de ativação está bloqueado.
 * @param {string} activationMailSentAt - Data em que o email de ativação foi enviado no utc string.
 */
export const useActivationEmailBlocked = (activationMailSentAt) => {
  const { formattedDate } = useFormattedDate({
    date: activationMailSentAt,
    dateTimeFormat: true,
  });

  const isActivationEmailBlocked = useMemo(() => {
    if (!activationMailSentAt) return false;

    const activationDateUTC = new Date(activationMailSentAt);

    if (isNaN(activationDateUTC)) {
      console.error(
        'Invalid date format for activationMailSentAt:',
        activationMailSentAt
      );
      return false;
    }

    const fiveMinutesAgoUTC = new Date(Date.now() - 5 * 60 * 1000);

    return activationDateUTC < fiveMinutesAgoUTC;
  }, [activationMailSentAt]);

  return {
    isActivationEmailBlocked,
    formattedDate: activationMailSentAt ? formattedDate : null,
  };
};
