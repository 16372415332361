import PropTypes from 'prop-types';
import i18n from 'i18next';

import { TableAction } from '../../TableAction.component';
const BUTTON_PREFIX = 'commons.buttons.actions-buttons';

export function DeleteTableAction({ onDelete, isDeleting }) {
  return (
    <TableAction
      tooltip={i18n.t(`${BUTTON_PREFIX}.delete`)}
      iconName="delete"
      isConfirm
      isLoading={isDeleting}
      onClick={onDelete}
    />
  );
}

DeleteTableAction.propTypes = {
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
};
