import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { device } from 'modules/Utils/Devices';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  padding: 0 1.6rem;
  background-color: ${(props) => props.theme.config.colors.primary};
  color: ${(props) => props.theme.config.colors.light};
  border: none;
  border-radius: 3.5rem;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease;

  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}

  ${(props) =>
    props.small &&
    css`
      height: 32px;
      min-width: 10.4rem;
      ${(props) => props.theme.config.typograph.xsmall};
    `}

  ${(props) =>
    props.medium &&
    css`
      height: 40px;
      min-width: 10.4rem;
      ${(props) => props.theme.config.typograph.xsmall};
    `}

  ${(props) =>
    props.large &&
    css`
      height: 48px;
      ${(props) => props.theme.config.typograph.default};
    `}

  &:focus,
  &:active,
  &:hover {
    background-color: ${shade(0.2, '#0075EA')};
  }

  &:disabled {
    color: ${(props) => props.theme.config.colors.light};
    background-color: ${(props) => props.theme.config.colors.gray_300};
    border-color: ${(props) => props.theme.config.colors.gray_300};
    cursor: not-allowed;

    &:focus,
    &:active,
    &:hover {
      background-color: ${(props) => props.theme.config.colors.gray_300};
      border-color: ${(props) => props.theme.config.colors.gray_300};
      color: ${(props) => props.theme.config.colors.light};

      svg path {
        fill: ${(props) => props.theme.config.colors.light};
      }
    }

    svg {
      path {
        color: ${(props) => props.theme.config.colors.light};
      }
    }
  }

  @media ${device.tablet} {
    ${(props) => props.theme.config.typograph.xsmall};
    padding: 1.3rem 1rem;
  }

  ${(props) =>
    props.light &&
    css`
      border-color: ${(props) => props.theme.config.colors.primary};
      color: ${(props) => props.theme.config.colors.primary};
      background: ${(props) => props.theme.config.colors.light};

      &:focus,
      &:active,
      &:hover {
        background-color: ${(props) =>
          shade(0.1, props.theme.config.colors.light)};
      }
    `}

  ${(props) =>
    props.transparent &&
    css`
      border: 1px solid ${(props) => props.theme.config.colors.primary};
      color: ${(props) => props.theme.config.colors.primary};
      background: transparent;

      &:focus,
      &:active {
        color: ${(props) => props.theme.config.colors.primary};
        background: transparent;
      }

      &:hover {
        color: ${(props) => props.theme.config.colors.light};
        background-color: ${(props) =>
          shade(0.1, props.theme.config.colors.primary)};

        svg path {
          fill: ${(props) => props.theme.config.colors.light};
        }
      }
    `}

  ${(props) =>
    props.danger &&
    css`
      background-color: ${(props) =>
        props.transparent ? 'transparent' : props.theme.config.colors.tertiary};
      color: ${(props) =>
        props.transparent
          ? props.theme.config.colors.tertiary
          : props.theme.config.colors.light};

      ${(props) =>
        props.transparent &&
        css`
          border: 1px solid ${(props) => props.theme.config.colors.tertiary};
        `};

      svg path {
        fill: ${(props) => props.theme.config.colors.light};
      }

      &:focus,
      &:active,
      &:hover {
        color: ${(props) => props.theme.config.colors.light};
        background-color: ${shade(0.1, props.theme.config.colors.tertiary)};
      }
    `};

  ${(props) =>
    props.noHoverEffect &&
    css`
      &:focus,
      &:active,
      &:hover {
        color: inherit;
        background-color: inherit;
      }
    `};

  ${(props) =>
    props.outlined &&
    css`
      border: 1px solid ${(props) => props.theme.config.colors.primary};
      color: ${(props) => props.theme.config.colors.primary};
      background: transparent;

      svg {
        path {
          fill: ${(props) => props.theme.config.colors.primary};
        }
      }

      &:focus,
      &:active,
      &:hover {
        color: ${(props) => props.theme.config.colors.light};
        background-color: ${(props) => props.theme.config.colors.primary};

        svg {
          path {
            fill: ${(props) => props.theme.config.colors.light};
          }
        }
      }
    `}

  ${(props) =>
    props.outline &&
    css`
      border: 1px solid transparent;
      background: transparent;
      color: ${(props) => props.theme.config.colors.gray};

      &:focus,
      &:active,
      &:hover {
        color: ${(props) => props.theme.config.colors.light};
        background: ${(props) => props.theme.config.colors.gray};
      }
    `}
`;

export { Button };
