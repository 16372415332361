import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Popover } from '@mui/material';
import { Tooltip } from 'modules/Core/Common';
import { copyToClipBoard } from 'modules/Utils/CopyToClipboard';
import { useUserAvatar } from 'modules/Users/Hooks';
import { EmailIcon, CheckIcon } from '@edusynch/edusynch-svg-icons';
import avatarPlusBadgeSvg from 'assets/avatar/avatar_plus_badge.svg';
import * as S from './UserAvatar.styles';
import i18n from 'i18next';

const AvatarContext = createContext();

const useAvatarContext = () => {
  const context = useContext(AvatarContext);
  if (!context) {
    throw new Error(
      'AvatarContext compound components cannot be rendered outside the CustomSelect component'
    );
  }
  return context;
};

const Photo = ({ showCursorPointer }) => {
  const {
    avatar,
    size,
    name,
    center,
    photoStyles,
    isUserCardPopoverOpen,
    handleAvatarPhotoClick,
  } = useAvatarContext();

  const handleClick = (event) => {
    if (isUserCardPopoverOpen) return;
    handleAvatarPhotoClick(event);
  };

  return (
    <>
      <S.AvatarCircle
        center={center}
        src={avatar}
        size={size}
        name={name}
        onClick={handleClick}
        showCursorPointer={showCursorPointer}
        style={photoStyles || {}}
      />
      <PlusBadge />
    </>
  );
};

Photo.propTypes = {
  showCursorPointer: PropTypes.bool,
};

const Name = ({ abridged }) => {
  const { name } = useAvatarContext();
  const abridgedName = useUserAvatar(name, 7);

  return (
    <S.Span data-test="avatar-user-name">
      {abridged ? abridgedName : name}
    </S.Span>
  );
};

Name.propTypes = {
  abridged: PropTypes.bool,
};

const PlusBadge = () => {
  const { isPlusEdition } = useAvatarContext();

  if (!isPlusEdition) return null;

  return (
    <Box
      data-test="avatar-user-plus-badge"
      id="avatar-plus-badge"
      sx={{
        position: 'absolute',
        left: '20px',
        top: '20px',
        zIndex: '1',
      }}
    >
      <img src={avatarPlusBadgeSvg} alt="Avatar plus badge" />
    </Box>
  );
};

const Label = () => {
  const { label } = useAvatarContext();
  return <S.Span>{label}</S.Span>;
};

const Card = () => {
  const {
    name,
    email,
    completedReview,
    userCardPopoverEl,
    isUserCardPopoverOpen,
    userCardPopoverId,
    handleCloseUserCardPopover,
  } = useAvatarContext();
  const [disableCopy, setDisableCopy] = useState(false);

  const copyEmailToClipboard = async () => {
    try {
      await copyToClipBoard(email);
    } finally {
      setTimeout(() => {
        setDisableCopy(true);
      }, 100);
    }

    setTimeout(() => {
      setDisableCopy(false);
    }, 5000);
  };

  return (
    <Popover
      id={userCardPopoverId}
      open={isUserCardPopoverOpen}
      anchorEl={userCardPopoverEl}
      onClose={handleCloseUserCardPopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      elevation={4}
    >
      <S.Card>
        <S.CardHeader>
          <Photo />
          <S.CardInfo>
            <S.Name>{name}</S.Name>
            <S.Email onClick={copyEmailToClipboard} disabled={disableCopy}>
              {email}
            </S.Email>
            {!!completedReview && (
              <S.Completed>
                <div>
                  <CheckIcon />
                </div>
                <span>
                  {i18n.t(
                    'modules.submissions.common.table.evaluator.completed'
                  )}
                </span>
              </S.Completed>
            )}
          </S.CardInfo>
        </S.CardHeader>
        {email && (
          <S.EmailButton href={`mailto:${email}`}>
            <EmailIcon />
            {i18n.t('modules.submissions.common.table.evaluator.send-email')}
          </S.EmailButton>
        )}
      </S.Card>
    </Popover>
  );
};

const Email = () => {
  const { email } = useAvatarContext();

  return (
    <Tooltip
      placement="bottom"
      overlay="Copy Email"
      overlayInnerStyle={{
        backgroundColor: '#0075EA',
        borderRadius: '4px',
        padding: '8px 16px',
        fontSize: '14px',
      }}
    >
      <EmailIcon
        onClick={() => copyToClipBoard(email)}
        style={{ cursor: 'pointer' }}
      />
    </Tooltip>
  );
};

const UserAvatar = ({
  children,
  id,
  size,
  name,
  email,
  label,
  center,
  avatar,
  completedReview,
  onShowDropdown,
  cardRef,
  photoStyles,
  isPlusEdition,
}) => {
  const [userCardPopoverEl, setPopoverAnchorEl] = useState(null);

  const handleAvatarPhotoClick = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleCloseUserCardPopover = () => setPopoverAnchorEl(null);

  const isUserCardPopoverOpen = Boolean(userCardPopoverEl);
  const userCardPopoverId = open ? 'user-card-popover' : undefined;

  const values = useMemo(
    () => ({
      size,
      id,
      name,
      email,
      label,
      avatar,
      center,
      completedReview,
      onShowDropdown,
      cardRef,
      photoStyles,
      isPlusEdition,
      userCardPopoverEl,
      isUserCardPopoverOpen,
      userCardPopoverId,
      handleAvatarPhotoClick,
      handleCloseUserCardPopover,
    }),
    [
      size,
      name,
      email,
      center,
      label,
      avatar,
      completedReview,
      cardRef,
      photoStyles,
      isPlusEdition,
      userCardPopoverEl,
      isUserCardPopoverOpen,
      userCardPopoverId,
      handleAvatarPhotoClick,
      handleCloseUserCardPopover,
    ]
  );

  if (!name) return '-';

  return (
    <AvatarContext.Provider value={values}>
      <S.Container>{children}</S.Container>
    </AvatarContext.Provider>
  );
};

UserAvatar.Photo = Photo;
UserAvatar.Name = Name;
UserAvatar.PlusBadge = PlusBadge;
UserAvatar.Email = Email;
UserAvatar.Card = Card;
UserAvatar.Label = Label;

UserAvatar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  avatar: PropTypes.string,
  size: PropTypes.string,
  center: PropTypes.bool,
  name: PropTypes.string,
  email: PropTypes.string,
  label: PropTypes.string,
  completedReview: PropTypes.bool,
  onShowDropdown: PropTypes.func,
  cardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  photoStyles: PropTypes.object,
  isPlusEdition: PropTypes.bool,
};

export default UserAvatar;
