import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ArrowDownIcon } from '@edusynch/edusynch-svg-icons';
import { Tooltip, TableEmptyData } from 'modules/Core/Common';
import { addDotsToName } from 'modules/Utils';
import UserLicenses from 'modules/Users/Common/UserLicenses';
import { Table } from '../Table';
import { Pagination } from './Pagination';
import { ScrollPagination } from './ScrollPagination';
import { TableCheckbox } from '../TableCheckbox';
import { TableToolbar } from '../TableToolbar';
import { useSelectionStatus } from 'modules/Core/Hooks';

import * as S from './TablePagination.style';

const mountHeader = (columns) =>
  columns.map(
    ({
      title,
      Icon,
      IconTitle,
      sort = true,
      centerTr = false,
      titleAsIcon = false,
      dotsSize,
      tooltip,
      key,
      width,
      onSort,
      thColor,
      hoverThColor,
      hoverIconColor,
      minWidth,
    }) => (
      <th key={key} width={width} style={{ minWidth }}>
        <Tooltip overlay={tooltip || title} disabled={!dotsSize}>
          <S.ThWrapper
            data-testid={title}
            centerTr={centerTr}
            IconTitle={IconTitle}
            titleAsIcon={titleAsIcon}
            sort={sort}
            onClick={onSort}
            color={thColor}
            hoverColor={hoverThColor}
            hoverIconColor={hoverIconColor}
          >
            {IconTitle && <IconTitle color="#ACABB7" />}
            {Icon ? <Icon color="#ACABB7" /> : addDotsToName(title, dotsSize)}
            {sort && <ArrowDownIcon width="10" color="#0075EA" />}
          </S.ThWrapper>
        </Tooltip>
      </th>
    )
  );

const mountBody = (
  data,
  columns,
  toggleId,
  toggleIdToShowInfoTr,
  onItemClick,
  toggleChild,
  additionalData,
  toggledChildren,
  tdFirstChildStyle,
  toggleProfileDropdownId,
  toggleChildDataMapField,
  itemsSelected,
  onSelect
) =>
  data.map((dataMap, index) => {
    const tds = columns.map(({ key, centerTd, render }) => {
      const content =
        typeof render === 'function'
          ? render({ ...dataMap, ...additionalData })
          : dataMap[key] || <TableEmptyData />;

      return (
        <td key={key} style={centerTd ? { textAlign: 'center' } : {}}>
          {content}
        </td>
      );
    });

    const props = !onItemClick
      ? {}
      : {
          onClick: () => onItemClick(dataMap, index),
        };

    return (
      <React.Fragment key={dataMap?.id || index}>
        <S.Tr
          toggleChild={dataMap.isToggled}
          isToggled={dataMap.id === toggleId}
          isInfoTrDisplayed={dataMap.id === toggleIdToShowInfoTr}
          isProfileDropdownOpen={
            toggleProfileDropdownId && +dataMap?.id !== +toggleProfileDropdownId
          }
          tdFirstChildStyle={tdFirstChildStyle}
          {...props}
        >
          {!!itemsSelected && (
            <TableCheckbox
              isSelected={itemsSelected?.includes(dataMap?.id)}
              onSelect={() => onSelect(dataMap?.id)}
            />
          )}
          {tds}
        </S.Tr>
        {dataMap?.children}
        {toggleChild === dataMap[toggleChildDataMapField] && toggledChildren}
        {toggleId === dataMap.id && (
          <UserLicenses user={dataMap} columnsSize={columns.length} />
        )}
      </React.Fragment>
    );
  });

const TablePagination = ({
  data,
  columns,
  toggleId,
  toggleIdToShowInfoTr,
  pagination,
  scrollPagination,
  onItemClick,
  toggleChild,
  additionalData,
  toggledChildren,
  firstTrIsBold = true,
  containerStyle = {},
  tdFirstChildStyle = {},
  toggleProfileDropdownId,
  toggleChildDataMapField,
  withoutBottomScroll,
  itemsSelected,
  tableToolbarActions,
  onSelect,
  onSelectAll,
  onClearSelection,
  ...props
}) => {
  const parsedItems = useMemo(
    () => data?.map((item) => item?.id) || [],
    [data]
  );
  const { areAllItemsSelected } = useSelectionStatus(
    parsedItems,
    itemsSelected
  );

  if (!data?.length) {
    return null;
  }

  return (
    <>
      <S.TableContainer
        customTableWidth={containerStyle.customTableWidth}
        withoutBottomScroll={withoutBottomScroll}
        style={containerStyle}
        selectable={!!itemsSelected}
      >
        {!!tableToolbarActions && (
          <TableToolbar
            listSize={data?.length}
            areSomeItemsSelected={itemsSelected?.length > 0}
            areAllItemsSelected={areAllItemsSelected}
            onSelectAll={onSelectAll}
            onClear={onClearSelection}
          >
            {tableToolbarActions}
          </TableToolbar>
        )}
        <Table
          {...props}
          firstTrIsBold={firstTrIsBold}
          data-cy={props.dataCy}
          selectable={!!itemsSelected}
        >
          <thead>
            <tr>
              {!!itemsSelected && <th style={{ width: 0 }} />}
              {mountHeader(columns)}
            </tr>
          </thead>

          <tbody>
            {mountBody(
              data,
              columns,
              toggleId,
              toggleIdToShowInfoTr,
              onItemClick,
              toggleChild,
              additionalData,
              toggledChildren,
              tdFirstChildStyle,
              toggleProfileDropdownId,
              toggleChildDataMapField,
              itemsSelected,
              onSelect
            )}
          </tbody>
        </Table>
      </S.TableContainer>
      {pagination && (
        <S.PaginationContainer>
          {!scrollPagination ? (
            <Pagination
              page={pagination.page}
              total={pagination.total}
              align={pagination.align}
              search={pagination.search}
              startDate={pagination.startDate}
              endDate={pagination.endDate}
              perPage={pagination.perPage}
              behavior={pagination.behavior}
              showInfo={pagination.showInfo}
              onToggleClick={pagination.onToggleClick}
              customQueryParams={pagination.customQueryParams}
              onNextPage={(nextPage) => {
                if (pagination.onNextPage) {
                  pagination.onNextPage(nextPage);
                }
              }}
            />
          ) : (
            <ScrollPagination
              page={pagination.page}
              total={pagination.total}
              perPage={pagination.perPage}
              onNextPage={(nextPage) => {
                if (pagination.onNextPage) {
                  pagination.onNextPage(nextPage);
                }
              }}
            />
          )}
        </S.PaginationContainer>
      )}
    </>
  );
};

TablePagination.defaultProps = {
  data: [],
  toggleId: null,
  toggleIdToShowInfoTr: null,
  pagination: null,
  additionalData: {},
  toggleChildDataMapField: 'exercise_id',
};

TablePagination.propTypes = {
  toggleId: PropTypes.number,
  toggleIdToShowInfoTr: PropTypes.number,
  onItemClick: PropTypes.func,
  pagination: PropTypes.object,
  scrollPagination: PropTypes.bool,
  toggleChild: PropTypes.number,
  firstTrIsBold: PropTypes.bool,
  containerStyle: PropTypes.object,
  additionalData: PropTypes.object,
  toggledChildren: PropTypes.element,
  tdFirstChildStyle: PropTypes.object,
  toggleProfileDropdownId: PropTypes.number,
  toggleChildDataMapField: PropTypes.string,
  columns: PropTypes.array.isRequired,
  dataCy: PropTypes.string,
  withoutBottomScroll: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  itemsSelected: PropTypes.array,
  tableToolbarActions: PropTypes.node,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  onClearSelection: PropTypes.func,
};

export default TablePagination;
