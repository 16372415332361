import PropTypes from 'prop-types';
import i18n from 'i18next';

import { TableAction } from '../../TableAction.component';
const BUTTON_PREFIX = 'commons.buttons.actions-buttons';

export function ViewTableAction({ onView }) {
  return (
    <TableAction
      tooltip={i18n.t(`${BUTTON_PREFIX}.view`)}
      iconName="eye"
      onClick={onView}
    />
  );
}

ViewTableAction.propTypes = {
  onView: PropTypes.func,
};
