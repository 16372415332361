import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useTheme } from 'styled-components';
import { Stack } from '@mui/material';
import { MUICheckbox } from 'modules/Core/Common';
import { TableToolbarClear } from './TableToolbarClear.component';

export function TableToolbar({
  listSize,
  areSomeItemsSelected,
  areAllItemsSelected,
  onSelectAll,
  onClear,
  children,
}) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        height: {
          xs: 'auto',
          sm: 48,
        },
        p: 2,
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        alignItems: {
          sm: 'center',
        },
        gap: {
          xs: 2,
          sm: 0,
        },
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottom: '2px solid',
        borderBottomColor: theme.config.colors.primary_200,
        bgcolor: theme.config.colors.primary_100,
      }}
    >
      <Stack
        sx={{
          '.MuiTypography-root': {
            fontSize: '14px',
            lineHeight: '16px',
            color: theme.config.colors.gray,
          },
        }}
      >
        <MUICheckbox
          checked={areAllItemsSelected}
          label={`${i18n.t('common-words.select-all')}${
            areSomeItemsSelected ? `(${listSize || 0})` : ''
          }`}
          onChange={(e) => onSelectAll(e.target.checked)}
          checkboxProps={{
            disableRipple: true,
          }}
        />
      </Stack>
      {!!areSomeItemsSelected && (
        <>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0.5,
              borderTop: {
                xs: '1px solid',
                sm: 0,
              },
              borderBottom: {
                xs: '1px solid',
                sm: 0,
              },
              borderLeft: {
                sm: '1px solid',
              },
              borderRight: {
                sm: '1px solid',
              },
              px: {
                sm: 2,
              },
              py: {
                xs: 2,
                sm: 0,
              },
              borderColor: theme.config.colors.gray_300,
            }}
          >
            {children}
          </Stack>
          <Stack sx={{ px: 2 }}>
            <TableToolbarClear onClick={onClear} />
          </Stack>
        </>
      )}
    </Stack>
  );
}

TableToolbar.propTypes = {
  listSize: PropTypes.number,
  areSomeItemsSelected: PropTypes.bool,
  areAllItemsSelected: PropTypes.bool,
  onSelectAll: PropTypes.func,
  onClear: PropTypes.func,
  children: PropTypes.node,
};
