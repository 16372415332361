import PropTypes from 'prop-types';
import { Box, LinearProgress } from '@mui/material';
import { Title } from 'modules/Core/Common';

export function ProgressBar(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Title size="mico" sx={{ color: 'text.secondary' }}>
          {`${Math.round(props.value)}%`}
        </Title>
      </Box>
    </Box>
  );
}

ProgressBar.propTypes = {
  value: PropTypes.number,
};
