import React from 'react';
import PropTypes from 'prop-types';

import {
  TableActions,
  ViewTableAction,
  EditTableAction,
  DeleteTableAction,
} from 'modules/Core/Common';
import { Can } from 'hoc/Ability';

export const DefaultTableActions = ({
  id,
  onView,
  onEdit,
  onDelete,
  isDeleting,
  subject,
  children,
}) => {
  const emptyProps = !id && !onView && !onEdit && !onDelete;

  if (emptyProps) return null;

  return (
    <TableActions>
      {!!onView && (
        <Can I="index" a={subject}>
          <ViewTableAction onView={() => onView(id)} />
        </Can>
      )}
      {!!onEdit && (
        <Can I="update" a={subject}>
          <EditTableAction onEdit={() => onEdit(id)} />
        </Can>
      )}
      {!!onDelete && (
        <Can I="destroy" a={subject}>
          <DeleteTableAction
            isDeleting={isDeleting}
            onDelete={() => onDelete(id)}
          />
        </Can>
      )}
      {children}
    </TableActions>
  );
};

DefaultTableActions.propTypes = {
  id: PropTypes.number,
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  subject: PropTypes.string,
  children: PropTypes.node,
};
