import { useMemo } from 'react';
import { useAuth } from 'modules/Auth/Hooks';
import { format, utcToTimezone } from 'modules/Utils/Date';

export const useFormattedDate = ({ date, dateFormat, dateTimeFormat }) => {
  const { userTimezone, userDateFormat, userTimeFormat } = useAuth();

  const formattedDate = useMemo(() => {
    if (!date) {
      return null;
    }

    const adjustedDate = utcToTimezone(date, userTimezone);
    const effectiveDateFormat = dateFormat || userDateFormat;
    const effectiveOutputFormat = dateTimeFormat
      ? `${effectiveDateFormat} ${userTimeFormat}`
      : effectiveDateFormat;

    return format(adjustedDate, effectiveOutputFormat);
  }, [
    date,
    dateFormat,
    dateTimeFormat,
    userTimezone,
    userDateFormat,
    userTimeFormat,
  ]);

  return { formattedDate };
};
