import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@mui/material';
import { Tooltip, ConfirmPopover, Icon } from 'modules/Core/Common';

export const TableAction = ({
  tooltip,
  iconName,
  isConfirm = false,
  isLoading = false,
  onClick,
  children,
  ...props
}) => (
  <Tooltip
    placement="bottom"
    overlay={tooltip}
    secondaryTooltip
    overlayInnerStyle={{
      backgroundColor: '#0075EA',
      borderRadius: '4px',
      padding: '8px 16px',
    }}
  >
    {isConfirm ? (
      <ConfirmPopover
        onConfirm={onClick}
        isLoading={isLoading}
        subtitle="This action will delete this item."
      >
        <IconButton size="small" onClick={onClick} {...props}>
          {children || <Icon name={iconName} size="14px" />}
        </IconButton>
      </ConfirmPopover>
    ) : (
      <IconButton size="small" onClick={onClick} {...props}>
        {children || <Icon name={iconName} size="14px" />}
      </IconButton>
    )}
  </Tooltip>
);

TableAction.propTypes = {
  tooltip: PropTypes.node,
  iconName: PropTypes.string,
  isConfirm: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};
