import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Popover } from '@mui/material';

import { Icon, Button, IconRoundBox } from 'modules/Core/Common';
import i18n from 'i18next';

import * as S from './ConfirmPopover.styles';

export const ConfirmPopover = ({
  title = 'Are you sure?',
  subtitle,
  isLoading,
  onConfirm,
  children,
  ...props
}) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'confirm-tooltip-popover' : undefined;

  const handleConfirm = () => {
    onConfirm();
    setAnchorEl(null);
  };

  return (
    <S.Container data-cy="confirmTooltip.container" {...props}>
      {cloneElement(children, {
        onClick: handleClick,
      })}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableEscapeKeyDown={isLoading}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <S.Confirm>
          <IconRoundBox
            withBg
            bg={theme.config.colors.primary_100}
            style={{ margin: '0 auto' }}
          >
            <Icon name="exclamation" />
          </IconRoundBox>
          <h4>{title}</h4>
          {subtitle && <p>{subtitle}</p>}
          <S.Actions>
            <Button
              transparent
              medium
              onClick={handleClose}
              disabled={isLoading}
            >
              {i18n.t('commons.buttons.cancel-button')}
            </Button>
            <Button
              dataCy="confirmPopover.confirmButton"
              data-testid="confirmPopover.confirmButton"
              medium
              loading={isLoading}
              hasLoading={isLoading}
              disabled={isLoading}
              loadingText=""
              onClick={handleConfirm}
            >
              {i18n.t('commons.buttons.confirm-button')}
            </Button>
          </S.Actions>
        </S.Confirm>
      </Popover>
    </S.Container>
  );
};

ConfirmPopover.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isLoading: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node,
};
