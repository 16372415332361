import styled from 'styled-components';
import { Avatar } from 'modules/Core/Common';

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: max-content;

  svg {
    width: 16px;
    opacity: 0.5;

    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }
  }
`;

export const AvatarCircle = styled(Avatar)`
  cursor: pointer;
  flex-shrink: 0;
`;

export const Span = styled.span`
  margin-left: ${(props) => props.theme.config.spacing.spacing_2};
  font-weight: bold;
  flex: 1;
`;

export const Card = styled.div`
  background: ${(props) => props.theme.config.colors.light};
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  min-width: 32rem;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem;

  > :first-child {
    width: 6.4rem;
    height: 6.4rem;
    min-width: 6.4rem;
    font-size: 2.4rem;
  }

  > #avatar-plus-badge {
    left: 56px;
    top: 62px;

    > img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const CardInfo = styled.div`
  display: block;
  margin-left: ${(props) => props.theme.config.spacing.spacing_3};

  > :last-child {
    ${(props) => props.theme.config.typograph.mico};
  }
`;

export const Name = styled.div`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  font-weight: bold;
  margin-bottom: 0.6rem;
`;

export const Email = styled.button`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const EmailButton = styled.a`
  ${(props) => props.theme.config.typograph.xsmall};
  border-top: 1px solid ${(props) => props.theme.config.colors.gray_200};
  color: ${(props) => props.theme.config.colors.primary};
  padding: 1.6rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 0.8rem;
    opacity: 1;

    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }
`;

export const Completed = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  div {
    margin-right: 1rem;
    background-color: ${(props) => props.theme.config.colors.green};
    border-radius: 50%;
    height: 1.6rem;
    width: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 0.8rem;
      opacity: 1;

      path {
        fill: ${(props) => props.theme.config.colors.light};
      }
    }
  }

  span {
    color: ${(props) => props.theme.config.colors.green};
    ${(props) => props.theme.config.typograph.xsmall};
  }
`;
