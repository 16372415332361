import { useState, useRef, useCallback } from 'react';

export function useDeleteWithProgress() {
  const [progress, setProgress] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const cancelDeletionController = useRef(null);

  const cancelDeletion = useCallback(() => {
    if (cancelDeletionController.current) {
      cancelDeletionController.current.abort();
    }
  }, [cancelDeletionController]);

  return {
    progress,
    setProgress,
    isDeleting,
    setIsDeleting,
    cancelDeletion,
    cancelDeletionController,
  };
}
