import PropTypes from 'prop-types';

import { TableToolbarAction } from 'modules/Core/Common';

export function UsersTableToolbarActions({ onDeleteAll }) {
  return (
    <>
      <TableToolbarAction
        iconName="delete"
        title="Delete"
        onClick={onDeleteAll}
      />
    </>
  );
}

UsersTableToolbarActions.propTypes = {
  onDeleteAll: PropTypes.func,
};
