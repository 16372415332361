import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { ButtonBase } from '@mui/material';
import { Icon } from 'modules/Core/Common';

export function TableToolbarAction({ iconName, title, onClick }) {
  const theme = useTheme();

  return (
    <ButtonBase
      type="button"
      onClick={onClick}
      sx={{
        px: 1,
        color: theme.config.colors.gray,
        borderRadius: '4px',
        height: 28,
        transition: 'all 0.2s',
        '&:hover': {
          color: theme.config.colors.primary_400,
          bgcolor: theme.config.colors.primary_200,
          svg: {
            path: {
              fill: theme.config.colors.primary_400,
            },
          },
        },
      }}
    >
      <Icon left name={iconName} size="12px" color={theme.config.colors.gray} />
      {title}
    </ButtonBase>
  );
}

TableToolbarAction.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};
